import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import DemographicsCharts from "../components/demographics-charts"
import DemographicsTable from "../components/demographics-table"
import Bio from "../components/bio"

import DataTable from "react-data-table-component"
import Dropdown from "react-dropdown"
import 'react-dropdown/style.css'
import styled from "styled-components"
import _ from "underscore"

// Define filtering components outside so that they don't re-render
// and input doesn't lose its focus
const Input = styled.input``;
const Button = styled.a``;

const getMedian = function(arr) {
  return arr.length % 2 === 1
  ? arr[parseInt(arr.length / 2)]
  : ((arr[ arr.length / 2 - 1] + arr[arr.length / 2]) / 2);
}

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="w-100 ml0 pl0">
    <Input
      id="search"
      className="pv1 mb2 mr2"
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={onFilter}
    />

    <Button
      className="f6 link dim ph3 pv2 mb2 dib white pointer"
      style={{backgroundColor: '#2D3356'}}
      onClick={onClear}
    >
      Clear
    </Button>
  </div>
);

export default props => {

  const org = props.pageContext.organization;

  // React variables
  const [filterText, setFilterText] = React.useState('')
  const [demographicsTableVisibility, setDemographicsTableVisibility] = React.useState(false)
  const [year, setYear] = React.useState('2023')

  // Make sure document exists before enabling pagination, otherwise Gatsby won't compile
  // https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/util.js#L120
  const [pagination, setPagination] = React.useState(false)
  useEffect(() => {
    if (document) {
      setPagination(true)
      setFilterText(props.location.hash.replace(/%20/g, ' ').substr(1) || '')
    }
  }, [props.location.hash])

  // Set relevant data year, and convert Salary and Fringe to integers
  var data = props.pageContext.payroll
    .filter(x => x.CalYear === year)
    .map(function(o) {
      o.Salary = Math.round( parseFloat(o.Salary) );
      o.Fringe = Math.round( parseFloat(o.Fringe) );
      o.Key = [o.FirstName, o.LastName, o.Title].join(' ');
      o.Total = Math.round( o.Salary + o.Fringe );
      o.Name = o.FirstName + ' ' + o.LastName;
      return o;
    });

  var demographics = props.pageContext.demographics.filter(x => x.CalYear === year);

  // This dataset is only used by the table, because filtering should not affect demographics charts
  const dataFiltered = data
    .filter( x => x.Key.toUpperCase().includes( filterText.toUpperCase() ));


  const nTotal = demographics.length;

  // Full/Part Time
  const nFullPart = _.countBy(demographics, 'FullPart');

  // Gender
  const nGender = _.countBy(demographics, 'Gender');
  nGender['FP'] = Math.round( (nGender.F || 0) / nTotal * 100 );
  nGender['MP'] = Math.round( (nGender.M || 0) / nTotal * 100 );

  // Races
  const nRace = _.countBy(demographics, 'Race')
  nRace['O'] = (nRace.P || 0) + (nRace.I || 0) + (nRace.T || 0) // Other = Am Indian + Pacific Islander + Two or More
  nRace['U'] = nTotal - (
    (nRace.W || 0) + (nRace.H || 0) +
    (nRace.B || 0) + (nRace.A || 0) + nRace.O )

  // Median age
  const ages = demographics.map(o => parseInt(o.Age)).sort();
  const medianAge = getMedian(ages);

  // Table Filtering Component
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText('');
      }
    };

    return <FilterComponent
      onFilter={e => setFilterText(e.target.value)}
      onClear={handleClear}
      filterText={filterText}
    />
    }, [filterText]);

  return (
    <Layout>

      <div className="cf pv3">
        <h2 className="f2 fw3 ma0 di">
          <Link to="/payroll" className="link black underline-hover">Payroll</Link>
        </h2>
        <h2 className="f2 fw3 ma0 moon-gray di"> &gt; </h2>
        <h1 className="f2 fw3 ma0 mr4 di">{org}</h1>
      </div>

      <Bio
        bio={props.pageContext.bio.Bio}
        home={props.pageContext.bio.Home}
        report={props.pageContext.bio.Report}
      />

      <div className="cf bg-light-gray pv2 ph3 sticky bb bw1 b--moon-gray">

        <h4 className="f4 fl mv2 fw4"> Show demographics and payroll data for </h4>

        <Dropdown
          options={['2019', '2020', '2021', '2022', '2023']}
          onChange={(v) => setYear(v.value) }
          className='mw4 fl mh2'
          placeholder={year}
          value={year}
        />

        <h4 className="f4 fl mv2 fw4">
          calendar year.
        </h4>

      </div>

      {nTotal > 0 && 
      <article className="bg-near-white mv3 pa3 cf">
        <h3 className="f3 mt0"> Demographics </h3>

        <p className="f4">
          In {year}, {org} had {nTotal} employees
          whose median age was {medianAge || ' not reported'}.
        </p>

        <p className="f5 measure-wide">
          The breakdown figures below may not add up to the total because of missing demographics data for some employees.
        </p>

        <DemographicsCharts
          nTotal={nTotal}
          nGender={nGender}
          nFullPart={nFullPart}
          nRace={nRace}
          org={org}
        />

        <Button
          href="#"
          className="db f4 fw4 mt4 mb2 pointer dim link black"
          onClick={() => setDemographicsTableVisibility(!demographicsTableVisibility)}
          onKeyDown={() => setDemographicsTableVisibility(!demographicsTableVisibility)}>
          How does it compare to other quasi-public organizations? {demographicsTableVisibility ? '🔼' : '🔽'}
        </Button>

        <DemographicsTable year={year} org={org} visibility={demographicsTableVisibility} />

      </article>
      }

      {nTotal === 0 && 
        <article className="bg-near-white mv3 pa3 cf">
          <h3 className="f3 mt0"> Demographics </h3>
          <p className="f4">
            Demographic data for {org} employees in {year} is not available.
          </p>
        </article>
      }

      
      <h3 className="f3 mt5"> Employees and Compensation </h3>

      <DataTable

        title='Employees and Compensation'
        keyField='Key'
        noHeader

        columns={[
          {
            name: 'Employee',
            selector: 'Name',
            sortable: true,
            grow: 4,
            style: {
              fontWeight: 'bold'
            }
          },
          {
            name: 'Job Title',
            selector: 'Title',
            grow: 3,
            sortable: true,
          },
          {
            name: 'Empl Type',
            selector: 'FullPart',
            sortable: true,
            right: true,
            width: '40px',
            format: d => d.FullPart === 'F' ? 'Full' : 'Part'
          },
          {
            name: 'Salaries & Wages',
            right: true,
            selector: 'Salary',
            sortable: true,
            format: d => '$' + d.Salary.toLocaleString(),
            style: {
              align: ''
            }
          },
          {
            name: 'Fringe*',
            right: true,
            selector: 'Fringe',
            sortable: true,
            format: d => '$' + d.Fringe.toLocaleString()
          },
          {
            name: 'Total Compensation',
            right: true,
            selector: 'Total',
            sortable: true,
            width: '110px',
            format: d => '$' + d.Total.toLocaleString(),
            style: {
              fontWeight: 'bold'
            }
          },
          {
            name: 'Hire Date',
            selector: 'Hire',
            sortable: true,
            right: true,
            width: '85px'
          },
          {
            name: 'Term Date',
            selector: 'Term',
            sortable: true,
            right: true,
          },
        ]}

        defaultSortField='Pay'
        defaultSortAsc={false}

        data={dataFiltered}
        dense

        subHeader
        subHeaderComponent={subHeaderComponentMemo}

        pagination={pagination}
        paginationPerPage={20}
        paginationRowsPerPageOptions={[20, 50, 100]}

        highlightOnHover

        customStyles={{
          subHeader: {
            style: {
              paddingLeft: '0'
            }
          },
          headRow: {
            style: {
              textAlign: 'right'
            }
          },
          cells: {
            style: {
              paddingLeft: '0',
              paddingRight: '6px'
            }
          },
          headCells: {
            style: {
              fontSize: '12px',
              paddingLeft: '0',
              paddingRight: '6px',
              fontWeight: 'bold',
            }
          },
          rows: {
            style: {
              fontSize: '13px'
            }
          }
        }}
        />

        <p className="f6 measure-wide">
          <sup>*</sup><b>Fringe</b> is the cost associated with employment other than salary and wages.
          This typically includes the employer's share of health and retirement benefits but may vary across each entity.
          Quasi-public agencies utilizing the state's CORE-CT payroll system will show significantly higher fringe rates
          due costs including a number of components, some of which are not related to the cost of the benefits earned by
          the employee to whom they applied. For more detailed information about how totals for fringe are calculated,
          please inquire directly with the quasi-public agency.
        </p>

    </Layout>
  )
}