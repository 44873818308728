import React from "react"
import IconGlobe from "./icon-globe"
import IconReport from "./icon-report"

export default props => {

  if (props.bio !== '') {
    return (
      <div className="cf mb2">
        <p className="fl mt0 f5 w-100 w-two-thirds-l pr0 pr5-l">{props.bio}</p>
  
        <div className="fl f4 w-100 w-third-l">
          <div>
            <a href={props.home} className="link black dim inline-flex items-center" target="_blank" rel="noopener noreferrer">
              <IconGlobe />
              Homepage
            </a>
  
            <br />
  
            <a href={props.report} className="link black dim inline-flex items-center" target="_blank" rel="noopener noreferrer">
              <IconReport />
              Annual Reports
            </a>
  
          </div>
        </div>
      </div>
    )
  }

  return (<div></div>)

}