import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import _ from "underscore"

import DataTable from "react-data-table-component"

export default props => {

  const data = useStaticQuery(graphql`
    query myQuery {
      allDemographicsCsv {
        group(field: Org) {
          fieldValue
          nodes {
            CalYear
            Age
            Gender
            Race
            FullPart
          }
        }
      }
    }
  `)
  
  const getMedian = function(arr) {
    return arr.length % 2 === 1
      ? arr[parseInt(arr.length / 2)]
      : ((arr[ arr.length / 2 - 1] + arr[arr.length / 2]) / 2);
  }

  const dataFiltered = data.allDemographicsCsv.group.map(o => {
    const empl = o.nodes.filter(x => x.CalYear === props.year);
    o.FullPart = _.countBy(empl, 'FullPart');
    o.Gender = _.countBy(empl, 'Gender');
    o.Race = _.countBy(empl, 'Race');
    o.Age = empl.map(x => parseFloat(x.Age)).sort();
    
    o.Total = empl.length;
    o.PercentWomen = Math.round(o.Gender.F / o.Total * 100) || '?';
    o.PercentFullTime = Math.round(o.FullPart.F / o.Total * 100);
    //o.PercentNonWhite = Math.round( (o.Total - o.Race.W - o.Race.U) / (o.Total - o.Race.U) * 100 ) || '';
    o.PercentWhite = Math.round( o.Race.W / o.Total * 100) || 0;
    o.PercentBlack = Math.round( o.Race.B / o.Total * 100) || 0;
    o.PercentAsian = Math.round( o.Race.A / o.Total * 100) || 0;
    o.PercentHispanic = Math.round( o.Race.H / o.Total * 100) || 0;
    o.PercentOther = Math.round( o.Race.O / o.Total * 100) || 0;
    o.MedianAge = getMedian(o.Age) || '?';

    return o;
  });

  return (
    props.visibility ? 
      <DataTable

      title="Demographics by Quasi-Public Organization"
      keyField='fieldValue'
      className={props.className}
      noHeader
      dense
      highlightOnHover

      columns={[
        {
          name: 'Organization',
          selector: 'fieldValue',
          sortable: true,
          grow: 1,
        },
        {
          name: 'Employed',
          selector: 'Total',
          sortable: true,
          right: true,
          width: '65px'
        },
        {
          name: 'Median Age',
          selector: 'MedianAge',
          sortable: true,
          right: true,
          width: '65px'
        },
        {
          name: '% Women',
          selector: 'PercentWomen',
          sortable: true,
          right: true,
          width: '65px'
        },
        /*
        {
          name: '% Full Time',
          selector: 'PercentFullTime',
          sortable: true,
          right: true
        }, */
        {
          name: '% White',
          selector: 'PercentWhite',
          sortable: true,
          right: true,
          width: '55px'
        },
        {
          name: '% Black',
          selector: 'PercentBlack',
          sortable: true,
          right: true,
          width: '55px'
        },
        {
          name: '% Hisp',
          selector: 'PercentHispanic',
          sortable: true,
          right: true,
          width: '55px'
        },
        {
          name: '% Asian',
          selector: 'PercentAsian',
          sortable: true,
          right: true,
          width: '55px'
        },
        {
          name: '% Other Race',
          selector: 'PercentOther',
          sortable: true,
          right: true,
          width: '55px'
        }
      ]}

      data={dataFiltered}

      customStyles={{
        headRow: {
          style: {
            padding: '10px 0',
            textAlign: 'right'
          }
        },
        tableWrapper: {
          style: {
            padding: '0 8px',
            backgroundColor: 'white'
          }
        },
        rows: {
          style: {
            padding: '0 4px',
          }
        },
        headCells: {
          style: {
            paddingLeft: '2px',
            paddingRight: '2px'
          }
        },
        cells: {
          style: {
            paddingLeft: '2px',
            paddingRight: '2px'
          }
        },
        table: {
          style: {
            backgroundColor: 'blue',
            padding: 0,
          }
        },
      }}

      conditionalRowStyles={[
        {
          when: row => row.fieldValue === props.org,
          style: {
            backgroundColor: '#2D3356',
            color: 'white'
          }
        }
      ]}

    />
    : ''
  )
}