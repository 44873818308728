import React from "react"
import { Pie, HorizontalBar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"

export default props => {

  const pieChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: 'white',
        formatter: function(x) {
          var pt = Math.round( x / props.nTotal * 100 );
          return pt > 30 ? pt + '%' : ''
        }
      }
    }
  }

  return (
    <section className="w-100 cf">

      <div className="w-100 w-third-l fl ph2">
        <h4 className="f4 mb0">Gender</h4>

        <p>
          {props.org} had {props.nGender.F} female ({props.nGender.FP}%)
          and {props.nGender.M} male employee{props.nGender.M > 1 ? 's' : ''} ({props.nGender.MP}%).
        </p>

        <div className="relative">
          <Pie
            data={{
              labels: ['Female', 'Male'],
              datasets: [{
                label: 'Employees',
                data: [props.nGender.F, props.nGender.M],
                backgroundColor: ['#2D3356', 'silver'],
                borderWidth: 0
              }]
            }}
            options={pieChartOptions}
          />
        </div>

      </div>

      <div className="w-100 w-third-l fl ph2">

        <h4 className="f4 mb0">Employment Type</h4>

        <p>
          {props.nFullPart.F} were full-time employees,
          and {props.nFullPart.P || 'none'} {props.nFullPart.P === 1 ? 'was' : 'were'} employed part-time.
        </p>

        <div className="relative">
          <Pie
            data={{
              labels: ['Full-Time', 'Part-Time'],
              datasets: [{
                label: 'Employees',
                data: [props.nFullPart.F, props.nFullPart.P],
                backgroundColor: ['#2D3356', 'silver'],
                borderWidth: 0
              }]
            }}
            options={pieChartOptions}
            plugins={[ChartDataLabels]}
          />
        </div>

      </div>

      <div className="w-100 w-third-l fl ph2">
        <h4 className="f4 mb0">Race/Ethnicity</h4>

        <p>
          {props.nRace.W} identified as white,
          and {props.nTotal - props.nRace.W - props.nRace.U} as non-white.
          {props.nRace.U > 0 ? " Race is unknown for " + props.nRace.U + " employee" + (props.nRace.U === 1 ? '.' : 's.') : "" }
        </p>

        <div className="relative">
          <HorizontalBar
            data={{
              labels: ['White', 'Black', 'Hispanic', 'Asian', 'Other', 'Unknown'],
              datasets: [{
                label: 'Employees',
                data: [props.nRace.W, props.nRace.B, props.nRace.H, props.nRace.A, props.nRace.O, props.nRace.U],
                backgroundColor: '#2D3356',
                borderWidth: 0
              }]
            }}
            options={{
              legend: {
                display: false,
              },
              maintainAspectRatio: false,
              scales: {
                xAxes: [{
                  ticks: {
                    beginAtZero: true,
                    precision: 0, // integers
                  }
                }]
              },
              plugins: {
                datalabels: {
                  anchor: 'end',
                  align: 'left',
                  offset: 0,
                  formatter: function(x) {
                    var pt = Math.round( x / props.nTotal * 100 );
                    return pt > 5 ? x : ''
                  },
                  color: 'white'
                }
              }
            }}
          />
        </div>

      </div>

    </section>
  )

}